<template>
  <div>
    <el-dialog
        title="修改发票信息"
        :visible="true"
        width="30%"
        center
        :close-on-click-modal="false"
        :show-close="false">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
        <el-form-item label="发票名称" prop="fp_name" class="form-item">
          <el-input v-model="ruleForm.fp_name" size="mini" disabled></el-input>
        </el-form-item>
        <el-form-item label="发票来源" prop="source" class="form-item">
          <el-select v-model="ruleForm.type" placeholder="发票来源" size="mini" style="width: 100%">
            <el-option label="京东" :value="1"></el-option>
            <el-option label="知行邦" :value="2"></el-option>
            <el-option label="其他" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="归属时间" prop="belong" class="form-item">
          <el-date-picker
              v-model="ruleForm.belong"
              type="month"
              placeholder="请选择归属月份"
              size="mini"
              style="width: 100%"
              value-format="yyyy-MM">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="备注" prop="remark" class="form-item">
          <el-input v-model="ruleForm.remark" size="mini"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog" size="mini">取 消</el-button>
        <el-button type="primary" @click="updateForm" size="mini">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "EditTax",
  props:['taxData'],
  data(){
    return{
      centerDialogVisible: this.show,
      formData: this.taxData,
      ruleForm: JSON.parse(JSON.stringify(this.taxData)),
      rules:{
        fp_name:[ { required: true, message: '请输入发票名称', trigger: 'blur' },]
      }
    }
  },
  methods:{
    closeDialog(){
      this.$emit('closeIt', 'close')
    },
    updateForm(){
      axios.post('update_fp', this.ruleForm).then(res=>{
        if (res.data.meta.code === 200){
          this.$message.success('更新成功')
          this.$emit('refresh')
          this.closeDialog()
        }else {
          this.$message.error('更新失败')
        }
      })

    }
  }
}
</script>

<style scoped>
  .form-item{
    width: 80%;
  }
</style>