<template>
  <div>
    <el-container class="home-container">
      <el-aside width="200px">
        <el-menu
            :default-active="activeRouter"
            class="el-menu-vertical-demo"
            background-color="#333744"
            text-color="#fff"
            active-text-color="#409EFF"
            router
            :unique-opened="true"
        >
          <div class="title-div" @click="$router.push('/welcome')">
            <img src="../assets/zxb_logo.png" alt="" class="header_pic_2">
          </div>
          <el-menu-item index="/cloud">
            <i class="el-icon-upload"></i>
            <span slot="title">文件存储</span>
          </el-menu-item>
          <el-submenu index="tax">
            <template slot="title">
              <i class="el-icon-s-ticket"></i>
              <span>发票管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/tax">发票导入</el-menu-item>
              <el-menu-item index="/select">发票查询</el-menu-item>
              <!--                    <el-menu-item index="/download_tax">发票下载</el-menu-item>-->
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="order">
            <template slot="title">
              <i class="el-icon-s-order"></i>
              <span>订单管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/addorder">
                <span slot="title">添加订单</span>
              </el-menu-item>
              <el-menu-item index="/orders">
                <span slot="title">订单查询</span>
              </el-menu-item>
              <el-menu-item index="/settlement">
                <span slot="title">结算记录</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
<!--          <el-submenu index="inventory">-->
<!--            <template slot="title">-->
<!--              <i class="el-icon-s-home"></i>-->
<!--              <span>库存管理</span>-->
<!--            </template>-->
<!--            <el-menu-item-group>-->
<!--              <el-menu-item index="/goods">-->
<!--                <span slot="title">商品管理</span>-->
<!--              </el-menu-item>-->
<!--              <el-menu-item index="/warehousing">-->
<!--                <span slot="title">商品入库</span>-->
<!--              </el-menu-item>-->
<!--              <el-menu-item index="/out_warehousing">-->
<!--                <span slot="title">商品出库</span>-->
<!--              </el-menu-item>-->
<!--              <el-menu-item index="/select_warehousing">-->
<!--                <span slot="title">库存查询</span>-->
<!--              </el-menu-item>-->
<!--            </el-menu-item-group>-->
<!--          </el-submenu>-->

        </el-menu>
      </el-aside>
      <el-container class="right-side">
        <el-header>
          <div class="header">
            <span class="user"><i class="el-icon-user-solid user-icon"></i>{{ userName }}</span>
            <span>上次登录时间：<span class="logintime">{{ loginTime }}</span></span>
            <span class="loginout"><a href="#" @click="logout"><i class="el-icon-switch-button"></i> 注销</a></span>
          </div>
        </el-header>
        <el-main>
          <router-view></router-view>
        </el-main>
        <el-footer>
          <p>CopyRight All Reserved ©知行邦 2021</p>
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>

export default {
  name: "Home",
  async created() {
    this.userName = window.sessionStorage.getItem('nikeName')
    this.loginTime = window.sessionStorage.getItem('loginTime')
    this.activeRouter = this.$route.path
    const {data: res} = await this.axios.get('auth')
    if (res.meta.code == 206) {
      this.$message.error(res.meta.msg)
      return this.$router.push('/login')
    }
  },
  data() {
    return {
      loginTime: '',
      userName: '',
      activeRouter: ''
    }
  },
  methods: {
    logout() {
      window.sessionStorage.removeItem('token')
      window.sessionStorage.removeItem('nikeName')
      window.sessionStorage.removeItem('loginTime')
      this.$router.push('/login')
      // this.$message.success('注销成功，请重新登录')
    }
  }
}
</script>

<style scoped>
.right-side {
  background-color: rgb(242, 242, 242);
}

.home-container {
  height: 100vh;
}

/*.header_pic_1{*/
/*  position: absolute;*/
/*  top: 50%;*/
/*  left: 5px;*/
/*  transform: translateY(-50%);*/
/*  width: 80px;*/
/*}*/
.header_pic_2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 35px;
}

.el-aside {
  height: 100vh;
  background-color: #333744;
}

.el-header {
  padding: 0px 10px;
  margin-top: 10px;
}

.el-menu {
  border-right: 0;
}

.el-menu-item {
  padding-left: 25px;
}

.header {
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background-color: white;
  font-size: 12px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  line-height: 60px;
}

.title-div {
  position: relative;
  width: 200px;
  height: 70px;
}

.title-div :hover {
  /*background-color: #409EFF;*/
  cursor: pointer;
}

.title {
  font-size: 25px;
  line-height: 70px;
  color: white;
  text-align: center;
  margin: 0px auto;
}

.user {
  margin-left: 20px;
  padding-right: 30px;
  color: rgb(153, 153, 153);
}

.user .user-icon {
  font-size: 14px;
}

.logintime {
  color: rgb(153, 153, 153);
}

.loginout {
  float: right;
  margin-right: 30px;
}

.loginout a {
  color: #999;
}

.el-main {
  padding: 10px 10px;
}

.el-footer {
  padding: 0 10px;
  font-size: 12px;
  text-align: center;
  height: 30px !important;
  color: #999;
}

.footer {
  border-radius: 2px;
  background-color: white;

}
</style>