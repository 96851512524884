<template xmlns="">
  <div>
    <el-card class="box-card" id="boxCard">
      <!--            顶部功能区-->
      <el-row :gutter="20">
        <el-col :span="17">
          <el-button type="primary" @click="dialogVisible=true" size="mini">上传文件</el-button>
        </el-col>
        <el-col :span="7">
          <el-input placeholder="请输入搜索内容" class="input-with-select" clearable v-model="pages.keyWord"
                    @keyup.native.enter="startSearch" @clear="getFiles" @blur="loseFocus" size="mini">
            <el-button slot="append" icon="el-icon-search" @click="startSearch"></el-button>
          </el-input>
        </el-col>
      </el-row>
      <div class="table">
        <!--                表格-->
        <el-table :data="tableData" border style="width: 100%" :fit="true"
                  :highlight-current-row="true"
                  :height="tableHeight"
                  :stripe='true'
                  size="mini"
                  v-loading="loading"
                  element-loading-text="数据加载中"
                  element-loading-spinner="el-icon-loading">
          <el-table-column type="index" label="#"></el-table-column>
          <el-table-column prop="name" label="文件名" width="500"></el-table-column>
          <el-table-column prop="size" label="文件大小" width="120" align="center"></el-table-column>
          <el-table-column prop="type" label="文件类型" width="120" align="center"></el-table-column>
          <!--                    <el-table-column prop="uploader" label="上传者" width="150" align="center"></el-table-column>-->
          <el-table-column prop="up_time" label="上传时间" width="180" align="center"></el-table-column>
          <!--                    按钮-->
          <el-table-column min-width="300" align="center" label="操作">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" content="分享" :open-delay="delay" placement="top-start"
                          :enterable="false">
                <el-button type="success" size="mini" @click="copyUrl(scope.row)"><i class="el-icon-share"></i>
                </el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="下载" :open-delay="delay" placement="top-start"
                          :enterable="false">
                <el-button type="primary" size="mini" @click="downFils(scope.row)"><i class="el-icon-download"></i>
                </el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="删除" :open-delay="delay" placement="top-start"
                          :enterable="false">
                <el-popconfirm class="confirmButton" @confirm="deleteItem(scope.row)" confirm-button-text='删除'
                               cancel-button-text='取消' icon="el-icon-info" icon-color="rgb(255,153,0)" title="确定删除吗？">
                  <el-button type="danger" size="mini" slot="reference"><i class="el-icon-delete"></i></el-button>
                </el-popconfirm>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="重命名" :open-delay="delay" placement="top-start"
                          :enterable="false">
                <el-button type="warning" size="mini" @click="openRenameBox(scope.row)"><i class="el-icon-edit"></i>
                </el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <!--                上传-->
        <el-dialog
            title="上传文件"
            :visible.sync="dialogVisible"
            width="420px"
            :before-close="handleClose"
            :close-on-click-modal="false">
          <el-upload
              class="upload-demo"
              drag
              :action="this.globConfig.baseURL + 'upload_files'"
              :headers="uploadHeader()"
              :on-success="onSuccess"
              :file-list="fileList"
              :on-error="onError"
              multiple>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">单文件上传大小不能超过4GB</div>
          </el-upload>
        </el-dialog>
        <!--                分页-->
        <el-pagination
            @current-change="handleCurrentChange"
            background
            :current-page="pages.page"
            :page-size="pages.num"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  name: "Cloud",
  created() {
    // 计算表格高度
    this.getTableHeight()
    //获取文件列表
    this.getFiles()
  },
  data() {
    return {
      tableData: [],
      tableHeight: '',
      pages: {
        //一页多少条数据
        num: 0,
        //当前页码
        page: 1,
        //搜索的关键词
        keyWord: ''
      },
      //总的资源数量
      total: 0,
      dialogVisible: false,
      delay: 1000,
      fileList: [],
      loading: false
    }
  },
  methods: {
    getFiles() {
      this.loading = true
      // 请求数据
      axios.post('get_files', this.pages).then((res) => {
        if (res.data.meta.code != 200) return this.$message.error(res.data.meta.msg)
        this.tableData = res.data.data.files
        this.total = res.data.data.total
        this.loading = false
      })
    },
    getTableHeight() {
      const height = document.body.clientHeight
      const h = (height - 300) / 41
      if (h - parseInt(h) > 0.8) {
        this.pages.num = parseInt(h) + 1
      } else {
        this.pages.num = parseInt(h)
      }
      this.tableHeight = 41 * this.pages.num + 36 + 1

    },
    handleCurrentChange(num) {
      this.pages.page = num
      if (this.pages.keyWord) {
        this.searchFile()
      } else {
        this.getFiles()
      }
    },
    searchFile() {
      this.loading = true
      // 请求数据
      axios.post('search', this.pages).then((res) => {
        if (res.data.meta.code != 200) return this.$message.error(res.data.meta.msg)
        this.tableData = res.data.data.files
        this.total = res.data.data.total
        this.loading = false
      })
    },
    startSearch() {
      //将页码还原为1
      this.pages.page = 1
      this.searchFile()
    },
    loseFocus() {
      if (this.pages.keyWord) {
        this.startSearch()
      } else {
        this.getFiles()
      }
    },
    deleteItem(arg) {
      //删除操作
      axios.put('delete/' + arg.id).then(res => {
        if (res.data.meta.code != '200') {
          return this.$message.error(res.data.meta.msg)
        } else {
          this.getFiles()
          // return this.$message.success('删除成功')
          this.$notify({
            title: '删除成功',
            message: '您已删除 ' + arg.name,
            type: 'success'
          })
        }
      })
    },
    copyUrl(arg) {
      const url = this.globConfig.downURL + arg.name
      //复制文本内容到剪贴板
      this.$copyText(encodeURI(url)).then(() => {
        //成功
        return this.$message.success('下载地址已复制到剪切板')
      }).catch(() => {
        return this.$message.error('复制分享链接失败')
      })
    },
    openRenameBox(arg) {
      //分割文件名
      var name = arg.name.split('.')
      // 删除最后的格式
      const fileType = name.pop()
      this.$prompt('请输入文件名', '重命名', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        // inputPattern: '',
        // inputErrorMessage: '只能为中文字母数字下划线',
        inputValue: name.join('.')
      }).then(({value}) => {
        //文件名
        const fileName = value + '.' + fileType
        //点击确定 进行修改操作
        const newNameData = {
          id: arg.id,
          newName: fileName
        }
        // 发起重命名修改请求
        axios.post('rename', newNameData).then((res) => {
          if (res.data.meta.code != 200) {
            return this.$message.error(res.data.meta.msg)
          } else {
            this.getFiles()
            return this.$message.success("修改成功")
          }
        })
      }).catch(() => {
        //取消操作
        this.$message({
          type: 'info',
          message: '取消重命名'
        });
      });
    },
    handleClose() {
      this.dialogVisible = false
    },
    uploadHeader() {
      const token = window.sessionStorage.getItem('token')
      return {token: token}
    },
    onSuccess(response, file, file_list) {
      this.getFiles()
      // 三秒钟后清除列表的类容
      setTimeout(() => {
        file_list.shift()
      }, 3000)
    },
    onError() {
      this.$message.error("上传文件失败，请检查网络")
    },
    downFils(scope) {
      this.$notify({
        title: '下载文件',
        message: '开始下载>>' + scope.name,
        position: 'bottom-right'
      })
      // 跳转下载文件
      window.location.href = this.globConfig.downURL + scope.name
    }
  }
}
</script>

<style scoped>
.box-card {
  width: 100%;
  height: calc(100vh - 130px);
}

.upload-demo {
  text-align: center;
}

.confirmButton {
  margin: 0 10px;
}
</style>