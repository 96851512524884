<template>
  <div>
    <el-card class="box-card">
      <!--      面包屑导航-->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>订单管理</el-breadcrumb-item>
        <el-breadcrumb-item>订单查询</el-breadcrumb-item>
      </el-breadcrumb>
      <AllOrder></AllOrder>
    </el-card>
  </div>
</template>

<script>
import AllOrder from "./AllOrder";
import Settlement from "./Settlement";

export default {
  name: "index",
  data(){
    return{
      activeName: 'first',
    }
  },
  methods:{
    tabClick(tab, event){
      console.log(tab, event)
    }
  },
  components:{
    AllOrder,
    Settlement
  }
}
</script>

<style scoped>

</style>