<template>
  <!--        打开对话框-->
  <el-dialog :title="title" width="50%" :visible="true" v-if="show" @close="cloesDialog" :show-close="false">
    <div class="edit_info">
      <span class="category">
            <el-col :span="2">
                商品类别：
            </el-col>
              <el-select v-model="value" placeholder="请选择" size="mini">
              <el-option>
              </el-option>
              </el-select>
      </span>
      <span class="goods_name">
        <el-col :span="2">
          商品名称：
        </el-col>
        <el-input style="width: 50%" size="mini"></el-input>
      </span>
      <span class="goods_name">
        <el-col :span="2">
          京东编号：
        </el-col>
        <el-input style="width: 50%" size="mini"></el-input> <a href="" style="margin-left:10px" @click.prevent="getPriceInfo">获取信息</a>
      </span>
      <span class="pictuer">
              <el-col :span="2">
                商品图片：
              </el-col>
              <el-upload
                  action="#"
                  list-type="picture-card"
                  :auto-upload="false">
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{file}">
                <img
                    class="el-upload-list__item-thumbnail"
                    :src="file.url" alt=""
                >
                <span class="el-upload-list__item-actions">
                  <span
                      class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview(file)"
                  >
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span
                      v-if="!disabled"
                      class="el-upload-list__item-delete"
                      @click="handleDownload(file)"
                  >
                    <i class="el-icon-download"></i>
                  </span>
                  <span
                      v-if="!disabled"
                      class="el-upload-list__item-delete"
                      @click="handleRemove(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                  <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" alt="">
                  </el-dialog>
            </span>
    </div>
</el-upload>
            </span>
      <span class="button">
        <el-button type="primary" plain size="mini">确定</el-button>
        <el-button type="warning" plain size="mini" @click="cloesDialog">取消</el-button>
      </span>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "EditGoods",
  props: ['show', 'title'],
  data() {
    return {
      disabled: false,
      dialogTableVisible: this.show,
      dialogImageUrl: '',
      radio: 1,
    }
  },
  methods: {
    handleRemove(file) {
      console.log(file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    cloesDialog() {
      this.$emit('close', false)
    },
    getPriceInfo(){

    }
  }
}
</script>

<style scoped lang="scss">
.edit_info {
  display: flex;
  flex-direction: column;

  span {
    margin: 10px 0;
  }

  .pictuer {
    display: flex;
  }

  .goods_name {
    display: flex;
    align-items: center;
  }

  .button {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
}
</style>