<template xmlns="">
  <div>
    <el-row :gutter="10">
      <el-col :span="3">
        <el-select clearable @clear="filterFormData" @change="filterFormData" size="mini" v-model="department"
                   placeholder="请选择部门" style="width: 100%">
          <el-option
              v-for="item in departments"
              :key="item.id"
              :label="item.department"
              :value="item.id">
          </el-option>
        </el-select>
      </el-col>

      <el-col :span="2">
        <el-select size="mini" v-model="checked" placeholder="是否结算" @change="filterFormData" style="width: 100%">
          <el-option
              label="全部"
              :value="2">
          </el-option>
          <el-option
              label="已结算"
              :value="1">
          </el-option>
          <el-option
              label="未结算"
              :value="0">
          </el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-date-picker
            format="yyyy-MM"
            value-format="yyyy-MM-dd HH:mm:ss"
            style="width: 100%"
            v-model="month"
            type="monthrange"
            size="mini"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="1.5">
        <el-button type="primary" size="mini" style="width: 100%" @click="filterFormData">查询</el-button>
      </el-col>
      <!--      <el-col :span="1.5">-->
      <!--        <span class="info">已选择 {{ multipleSelection.length }} 条订单</span>-->
      <!--      </el-col>-->
      <el-col :span="1.5">
        <el-popconfirm
            confirm-button-text='结算'
            cancel-button-text='取消'
            icon="el-icon-edit-outline"
            icon-color="red"
            title="确定结算所选订单?"
            @confirm="checkOutOrders"
        >
          <el-button slot="reference" :disabled="!Boolean(notChecked.length)" type="primary" size="mini"
                     style="width: 100%"><i class="el-icon-edit-outline" style="font-size: 14px"></i> 结算所选订单
            ({{ notChecked.length }})
          </el-button>
        </el-popconfirm>
      </el-col>
      <el-col :span="1.5">
        <el-popconfirm
            confirm-button-text='删除'
            cancel-button-text='取消'
            icon="el-icon-delete"
            icon-color="red"
            title="确定删除所选订单?"
            @confirm="delOrders"
        >
          <el-button slot="reference" :disabled="!Boolean(notChecked.length)" type="primary" size="mini"
                     style="width: 100%"><i class="el-icon-delete" style="font-size: 14px"></i> 删除所选订单
            ({{ notChecked.length }})
          </el-button>
        </el-popconfirm>
      </el-col>
      <el-col :span="1.7">
        <el-tag
            effect="plain">
          当前页：已结算{{ hasCheckOut }} 元，
          未结算<span style="color: red"> {{ notCheckOut }} </span>元
        </el-tag>
      </el-col>
    </el-row>
    <!--    内容显示部分-->
    <div class="content">
      <el-table
          ref="multipleTable"
          :data="tableData"
          border
          style="width: 100%"
          :height="tableHeight"
          size="mini"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          @select="setCurrentRow"
          @selection-change="handleSelectionChange"
      >
        <el-table-column
            prop="checkbox"
            type="selection"
            :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
            prop="checkout"
            label="状态"
            align="center"
            width="80"
        >
          <template scope="scope">
            <el-tag type="warning" v-if="!scope.row.checkout" size="mini">未结算</el-tag>
            <el-tag type="success" v-if="scope.row.checkout" size="mini">已结算</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            prop="department"
            label="部门"
            align="center"
            width="180">
        </el-table-column>
        <el-table-column
            prop="item"
            label="事项"
            align="left"
            width="300"
            show-overflow-tooltip>
        </el-table-column>
        <el-table-column
            prop="consumables"
            label="商品/配件/耗材"
            align="left"
            width="200"
            show-overflow-tooltip>
        </el-table-column>
        <el-table-column
            prop="price"
            label="费用"
            align="center"
            width="80">
        </el-table-column>
        <el-table-column
            prop="manager"
            label="经办人"
            align="center"
            width="120"
            show-overflow-tooltip>
        </el-table-column>
        <el-table-column
            prop="server"
            label="服务员"
            align="center"
            width="120">
        </el-table-column>
        <el-table-column
            prop="date"
            label="时间"
            align="center"
            width="180">
        </el-table-column>
        <el-table-column
            prop="remark"
            label="备注"
            align="left"
            width="200"
            show-overflow-tooltip>
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            align="center"
            width="120">
          <template slot-scope="scope">
            <el-popconfirm
                confirm-button-text='删除'
                cancel-button-text='取消'
                icon="el-icon-delete"
                icon-color="red"
                title="确定删除该订单?"
                @confirm="delOrderById(scope.row)"
            >
              <el-button slot="reference" type="text" size="mini" :disabled="scope.row.checkout">删除</el-button>
            </el-popconfirm>
            <el-button type="text" size="small" :disabled="scope.row.checkout" @click="editOrder(scope.row)">编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--                分页-->
    <el-pagination
        @current-change="handleCurrentChange"
        background
        :current-page="pages.page"
        :page-size="pages.num"
        layout="total, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        title="编辑订单"
        :visible.sync="centerDialogVisible"
        width="50%"
        center>
      <EditForm v-if="centerDialogVisible" ref="editForm" :id="editingItem.id" :date="editingItem.date"
                :category="editingItem.category" :department="editingItem.department" :cost="editingItem.price"
                :remark="editingItem.remark" :servers="editingItem.servers" :item="editingItem.item"
                :manager="editingItem.manager" :haoCai="editingItem.consumables"
                :isConsumables="editingItem.hasConsumables"></EditForm>
      <span slot="footer" class="dialog-footer">
                <el-button @click="centerDialogVisible = false" size="mini">取 消</el-button>
                <el-button type="primary" @click="updateItem" size="mini">确 定</el-button>
              </span>
    </el-dialog>
  </div>
</template>

<script>
import EditForm from "../EditForm";

export default {
  name: "AllOrder",
  components: {
    EditForm
  },
  created() {
    this.getTableHeight()
    this.getAllOrder()
    this.getPartments()
  },
  data() {
    return {
      month: '',
      tableData: [],
      tableHeight: 750,
      loading: false,
      departments: [],
      department: '',
      checked: 2,
      queryData: {},
      // 勾选的 包括结算与未结算的
      multipleSelection: [],
      // 勾选且没有结算的
      notChecked: [],
      // 编辑对话框
      centerDialogVisible: false,
      editingItem: {},
      pages: {
        //一页多少条数据
        num: 1,
        //当前页码
        page: 1,
        //搜索的关键词
        keyWord: ''
      },
      //总的资源数量
      total: 0,
    }
  },
  methods: {
    // 结算所选订单
    async checkOutOrders() {
      if (this.department.length === 0) {
        return this.$message.error('请先选择部门再结算')
      }
      const {data: res} = await this.axios.post('check_out', this.notChecked)
      if (res.meta.code === 200) {
        this.$message.success('结算成功')
        this.notChecked.some((cVal) => {
          this.tableData[this.tableData.indexOf(cVal)].checkout = true
        })
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    async getAllOrder() {
      this.loading = true
      const {data: res} = await this.axios.get(`orders?page=${this.pages.page}&size=${this.pages.num}`)
      if (res.meta.code !== 200) {
        return this.$message.error(res.meta.msg)
      }
      this.total = res.data.total
      this.tableData = res.data.data
      this.loading = false
    },
    getTableHeight() {
      const height = document.body.clientHeight
      const h = (height - 300)
      this.tableHeight = h
      this.pages.num = parseInt((h - 37) / 45)
      if ((h - 37) - parseInt((h - 37)) > 0.8) {
        this.pages.num += 1
        this.tableHeight = 38 + 45 * this.pages.num
      }
    },
    async getPartments() {
      const {data: res} = await this.axios.get('departments')
      if (res.meta.code !== 200) {
        return this.$message.error(res.meta.msg)
      }
      this.departments = res.data
    },
    async filterFormData() {
      this.loading = true
      this.queryData.date = this.month
      this.queryData.department = this.department
      this.queryData.checked = this.checked
      // const postData = {date: this.month, department: this.department, checked: this.checked}
      // console.log(JSON.stringify(postData))
      const {data: res} = await this.axios.post('query_order', this.queryData)
      if (res.meta.code !== 200) {
        this.$message.error(res.meta.msg)
        return this.loading = false
      } else {
        this.tableData = res.data
        this.loading = false
      }
    },
    handleSelectionChange(rows) {
      // 设置选择框中的值为未结算的数据
      this.multipleSelection = rows
      this.notChecked = rows.filter(row => !row.checkout)
    },
    setCurrentRow(selection, row) {
      // if (row.checkout){
      //   this.$refs.multipleTable.toggleRowSelection(row, false)
      //   return this.$message.error("已经结算的订单不能再操作")
      // }
    },
    async delOrders() {
      const {data: res} = await this.axios.post('del_orders', this.notChecked)
      if (res.meta.code != 200) {
        this.$message.error(res.meta.msg)
      } else {
        this.notChecked.forEach((value) => {
          this.tableData.splice(this.tableData.indexOf(value), 1)
        })
        this.$message.success("删除成功")
      }
    },
    async delOrderById(scope) {
      const {data: res} = await this.axios.get(`del_order/${scope.id}`)
      if (res.meta.code != 200) {
        return this.$message.error(res.meta.msg)
      } else {
        this.tableData.splice(this.tableData.indexOf(scope), 1)
        this.$message.success("删除成功")
      }
    },
    editOrder(val) {
      this.centerDialogVisible = true
      this.editingItem = JSON.parse(JSON.stringify(val))
      this.editingItem.hasConsumables = Boolean(this.editingItem.consumables)
      this.editingItem.servers = this.editingItem.server ? this.editingItem.server.split('、') : []
      this.editingItem.consumables = this.editingItem.consumables ? this.editingItem.consumables.split(';') : []
      this.departments.some((list) => {
        if (list.department === this.editingItem.department) return this.editingItem.department = list.id
      })
    },
    async updateItem() {
      const {data: res} = await this.axios.post('update_order', this.$refs.editForm.form)
      if (res.meta.code != 200) return this.$message.error(res.meta.msg)
      this.tableData.some((arr, index) => {
        if (arr.id === this.$refs.editForm.form.id) {
          // 逐个更新表格中的内容
          this.tableData[index].category = this.$refs.editForm.form.category
          this.tableData[index].consumables = this.$refs.editForm.form.consumables.join(';').trim(';')
          this.tableData[index].date = this.$refs.editForm.form.date
          this.tableData[index].item = this.$refs.editForm.form.item
          this.tableData[index].manager = this.$refs.editForm.form.manager
          this.tableData[index].price = this.$refs.editForm.form.cost
          this.tableData[index].remark = this.$refs.editForm.form.remark
          this.tableData[index].server = this.$refs.editForm.form.servers.join('、').trim('、')
          this.departments.some((obj) => {
            if (obj.id == this.$refs.editForm.form.department) {
              this.tableData[index].department = obj.department
            }
          })
        }
      })
      this.centerDialogVisible = false
      return this.$message.success('更新成功')
    },
    handleCurrentChange(num) {
      this.pages.page = num
      this.getAllOrder()
    }
  },
  computed: {
    hasCheckOut() {
      return this.tableData.filter(row => row.checkout).reduce((count, row) => {
        return count + row.price
      }, 0)
    },
    notCheckOut() {
      return this.tableData.filter(row => !row.checkout).reduce((count, row) => {
        return count + row.price
      }, 0)
    }
  }
}
</script>

<style scoped>
.content {
  margin-top: 20px;
}

.info {
  display: inline-block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 13px;
  line-height: 30px;
  color: #606266;
}

/deep/ .el-table__body-wrapper::-webkit-scrollbar {
  width: 15px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 12px;
}

/deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  cursor: pointer;
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(64, 158, 255, 0.84);
}

/deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #ededed;
}
</style>