import Vue from 'vue'
import App from './App'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import router from './router'
import axios from "axios"
import Download from './utils/download'
import {Message} from "element-ui";
import * as echarts from 'echarts'


// 复制内容的插件
import VueClipboard from 'vue-clipboard2'
//使用复制内容的插件

Vue.use(VueClipboard)

Vue.prototype.globConfig = {
    baseURL: process.env["VUE_APP_BASEURL"],
    downURL: process.env["VUE_APP_DOWNURL"]
}
Vue.prototype.axios = axios
Vue.prototype.down = Download
Vue.prototype.$echarts = echarts


// 设置默认请求地址
axios.defaults.baseURL = process.env["VUE_APP_BASEURL"]
axios.interceptors.request.use(config => {
    config.headers.token = window.sessionStorage.getItem('token')
    return config
})

axios.interceptors.response.use(response => {
    //拦截响应，做统一处理
    if (response.data.meta.code == 206) {
        router.push('/login')
        return
    } else {
        return response
    }
},)

// 设置页面标题
router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})

Vue.use(ElementUI)
Vue.config.productionTip = false


new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
