<template>
  <el-card :style="{'height': get_height}">
    <!--      面包屑导航-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>库存管理</el-breadcrumb-item>
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div>
      <el-col style="margin: 10px 0">
        <el-button type="primary" size="mini" style="margin-right: 10px" @click="showEditForm=true">添加商品
        </el-button>
        <EditGoods :show="showEditForm" :title="dialogTitle" @close="closeForm"></EditGoods>
        <el-select v-model="value" placeholder="按分类查询" size="mini">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-col>
      <div class="show_goods">
        <div class="goods_item" v-for="i in ranges">
          <div class="img">
            <img
                src="https://img13.360buyimg.com/n1/jfs/t1/208621/3/15757/147338/61dfc307E9484483d/fc8a1f1eb58d2908.jpg"
                alt="">
          </div>
          <div class="info">
            <span class="category">类别：
              <el-select v-model="value" placeholder="请选择" size="mini">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
            </span>
            <span class="category">商品名称： 金士顿内存Beast野兽系列 Fury骇客神条 8G/16G/32G DDR4台式机电脑内存条</span>
            <span class="category">京东编号： 65914096358</span>
            <span class="category">型号： A2000</span>
            <span></span>
          </div>
          <div class="option">
            <span @click="editInfo(i)"><i class="el-icon-edit"></i></span>
            <span @click="deleteGoods(i)"><i class="el-icon-delete"></i></span>
          </div>
        </div>
      </div>
    </div>

  </el-card>
</template>

<script>
import EditGoods from "./EditGoods";

export default {
  name: "Goods",
  components: {EditGoods},
  data() {
    return {
      dialogTitle: '添加商品',
      dialogVisible: false,
      showEditForm: false,
      ranges: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,]
    }
  },
  computed: {
    get_height() {
      let height = document.body.clientHeight
      return (height - 130) + 'px'
    }
  },
  methods: {
    editInfo(e) {
      this.dialogTitle = '编辑商品信息'
      this.showEditForm = true
    },
    closeForm(e){
      this.showEditForm = e
    },
    deleteGoods(e){
      
    }
  }
}
</script>

<style scoped lang="scss">
.show_goods {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  width: 100%;
  height: calc(100vh - 230px);
  overflow: scroll;

  .goods_item {
    position: relative;
    width: 100%;
    height: 120px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: flex;
    //flex-direction: column;
    margin: 0 0 15px 0;
    padding: 10px;


    .img {
      width: 120px;
      height: 120px;
      overflow: hidden;

      img {
        width: 120px;
      }

    }

    .info {
      display: flex;
      flex-direction: column;
      margin-left: 10px;

      .category {
        font-size: 14px;
        color: #999;
      }

      span {
        margin: 3px 0;
      }
    }

    .option {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      display: flex;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        margin: 0 5px;
        border-radius: 50%;
        cursor: pointer;
        background-color: rgb(102, 177, 255);
        color: #fff;
        transition: all 0.3s;
      }

      span:hover {
        background-color: #84B7E8FF;
      }
    }

  }
}

.show_goods::-webkit-scrollbar {
  width: 0;
}

</style>