<template>
  <div>
    <el-card class="box-card">
      <!--      面包屑导航-->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>订单管理</el-breadcrumb-item>
        <el-breadcrumb-item>结算记录</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="container">
        <div class="card" v-for="item in logs_" :key="item.id">
          <el-row>
            <el-col :span="4">
              <span style="padding-left: 20px;: ">结算部门：</span>
              <span class="content">{{ item.department }}</span>
            </el-col>
            <el-col :span="4">
              <span style="padding-left: 20px;: ">结算时间：</span>
              <span class="content">{{ item.date }}</span>
            </el-col>
            <el-col :span="3">
              <span style="padding-left: 20px;: ">订单数量：</span>
              <span class="content">{{ item.order_count }}</span>
            </el-col>
            <el-col :span="3">
              <span style="padding-left: 20px;: ">结算金额：</span>
              <span class="content">{{ item.total_price }}</span>
            </el-col>
            <el-col :span="3">
              <span style="padding-left: 20px;: ">是否开票：</span>
              <span class="content">
            <el-switch
                v-model="item.invoice"
                @change="change_invoice(item)"
                active-color="#13ce66"
                inactive-color="#d7dbe2">
            </el-switch>
          </span>
            </el-col>
            <el-col :span="3">
              <span style="padding-left: 20px;: ">是否到账：</span>
              <span class="content">
            <el-switch
                v-model="item.is_get"
                @change="change_get(item)"
                active-color="#13ce66"
                inactive-color="#d7dbe2">
            </el-switch>
          </span>
            </el-col>
            <el-col :span="4">
              <el-popconfirm
                  confirm-button-text='确认'
                  cancel-button-text='取消'
                  icon="el-icon-info"
                  icon-color="red"
                  title="确定反结算该订单吗？"
                  @confirm="checkBack(item)"
              >
                <el-button type="danger" slot="reference" size="mini" style="margin-right: 10px">反结算</el-button>
              </el-popconfirm>
              <el-button type="primary" size="mini" @click="downloadReport(item)">下载报告</el-button>
            </el-col>
          </el-row>
        </div>
        <p class="bottom-text">到底啦~</p>
      </div>
    </el-card>
  </div>

</template>

<script>
// import Config from "../../config";

export default {
  name: "Settlement",
  created() {
    this.get_checkout_log()
  },
  data() {
    return {
      logs_: []
    }
  },
  methods: {
    async get_checkout_log() {
      const {data: res} = await this.axios.get('check_log')
      if (res.meta.code !== 200) {
        return this.$message.error('获取记录失败')
      } else {
        this.logs_ = res.data
      }
    },
    async change_invoice(item) {
      const status = item.invoice ? 1 : 0
      const {data: res} = await this.axios.get('change_invoice', {params: {id: item.id, status: status}})
    },
    async change_get(item) {
      const status = item.is_get ? 1 : 0
      const {data: res} = await this.axios.get('change_get', {params: {id: item.id, status: status}})
    },
    downloadReport(item) {
      window.open(process.env["VUE_APP_DOWNURL"] + 'excel/' + item.report_name)
    },
    async checkBack(item) {
      const {data: res} = await this.axios.get('check_back', {params: {id: item.id}})
      if (res.meta.code === 200) {
        this.$message.success('反结算成功')
        this.logs_.splice(this.logs_.findIndex(e => e === item), 1)
      }
    }

  }
}
</script>

<style scoped>
.container {
  height: calc(100vh - 230px);
  overflow-y: scroll;
  border: 1px solid #F2F6FC;
  padding: 10px;
}

.container::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

.container::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgb(64, 158, 255);
}

.container::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #ededed;
}

.card {
  font-size: 12px;
  line-height: 50px;
  width: 100%;
  height: 50px;
  margin: 15px auto;
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 2px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
}

.card:first-child {
  margin-top: 5px;
}

.content {
  color: #409EFF;
  font-size: 12px;
}

.bottom-text {
  text-align: center;
  color: #C0C4CC;
  font-size: 12px;
}
</style>