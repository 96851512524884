<template>
  <div>
    <el-card class="box-card">
<!--      面包屑导航-->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>发票管理</el-breadcrumb-item>
        <el-breadcrumb-item>发票导入</el-breadcrumb-item>
      </el-breadcrumb>
<!--      tab栏-->
      <template>
        <el-tabs v-model="activeName" @tab-click="handleClick">
<!--          第一个tab栏 发票导入-->
          <el-tab-pane label="XML文件导入" name="first">
            <!--              上传文件框-->
            <div class="uplaod-box" v-if="displayUpload">
              <el-upload
                  accept="xml"
                  class="upload-demo"
                  drag
                  :headers="getHeaders()"
                  :action="this.globConfig.baseURL + 'upload_xml'"
                  :on-success="onSuccess"
                  multiple>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">请上传发票xml文件,目前仅支持ukey批量导出版xml文件</div>
              </el-upload>
            </div>
<!--              发票信息核对-->
            <div class="table" v-if="displayTable">
              <el-scrollbar>
                <el-table
                    size="small"
                    :data="tableData"
                    style="width: 100%"
                    :height="tableHeight">
                  <el-table-column
                      fixed
                      type="index"
                      label="#"
                      width="50px">
                  </el-table-column>
                  <el-table-column
                      prop="fphm"
                      label="发票号码"
                      width="100px">
                  </el-table-column>
                  <el-table-column
                      prop="kprq"
                      label="开票日期"
                      width="100px">
                  </el-table-column>
                  <el-table-column
                      prop="gfmc"
                      label="购方名称"
                      width="120px"
                      :show-overflow-tooltip="true">
                  </el-table-column>
                  <el-table-column
                      prop="hjje"
                      label="合计金额"
                      width="100px">
                  </el-table-column>
                  <el-table-column
                      prop="hjse"
                      label="合计税额"
                      width="100px">
                  </el-table-column>
                  <el-table-column
                      prop="jshj"
                      label="价税合计"
                      width="100px">
                  </el-table-column>
                  <el-table-column
                      prop="spxx"
                      label="商品信息"
                      width="250px"
                      :show-overflow-tooltip="true">
                  </el-table-column>
                  <el-table-column
                      prop="is_issued"
                      label="是否代开"
                      width="80px"
                      align="center">
                    <template slot-scope="scope">
                      <el-switch
                          v-model="scope.row.is_issued"
                          active-color="#13ce66"
                          >
                      </el-switch>
                    </template>
                  </el-table-column>
                  <el-table-column
                      prop="khmc"
                      label="客户名称"
                      align="center"
                      width="120px">
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.khmc" placeholder="请输入姓名" size="mini"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column
                      prop="remark"
                      label="备注"
                      min-width="200px">
                    <template slot-scope="scope">
                      <el-input
                          size="mini"
                          placeholder="请输入备注"
                          v-model="scope.row.remark">
                      </el-input>
                    </template>
                  </el-table-column>
                </el-table>
              </el-scrollbar>
              <div class="bottom">
                <div class="info">
                  <span>共 {{ tableData.length }} 张发票</span>
                </div>
                <div class="button">
                  <el-button type="primary" plain size="mini" @click="submitTaxInfo">确认</el-button>
                  <el-button type="danger" plain size="mini">取消</el-button>
                </div>
              </div>
              </div>
          </el-tab-pane>
<!--          第二个tab栏-->
          <el-tab-pane label="手动输入" name="second">
            <el-empty description="开发中..."></el-empty>
          </el-tab-pane>
<!--          第三个tab栏-->
          <el-tab-pane label="excel导入" name="third">
            <el-empty description="开发中..."></el-empty>
          </el-tab-pane>
        </el-tabs>
      </template>
    </el-card>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "Tax",
  created() {
    this.getTableHeight()
  },
  mounted() {

  },
  data(){
    return {
      activeName:'first',
      displayUpload: true,
      displayTable: false,
      tableHeight:300,
      tableData: [],
    }
  },
  methods: {
    handleClick(){
    },
    //核对发票信息，提交
    submitTaxInfo(){
      const subData = []
      //遍历数据
      this.tableData.forEach( (e)=>{
        subData.push({
          fphm: e.fphm,
          kprq: e.kprq,
          gfmc: e.gfmc,
          hjje: e.hjje,
          hjse: e.hjse,
          spxx: e.spxx,
          bill_type: e.is_issued ? 1 : 0,
          khmc: e.khmc,
          remark: e.remark
        })
      })
      //发起请求
      axios.post('send_tax', subData).then((res)=>{
        const result = res.data
        if(result.meta.code == 200){
          this.$message.success("保存成功！")
          this.displayUpload = true
          this.displayTable = false
          this.tableData = []
          return
        }else {
          this.$message.error(result.meta.msg)
          this.displayUpload = true
          this.displayTable = false
          this.tableData = []
          return
        }
      })
    },
    onSuccess(s){
      const info = s.data
      if (s.meta.code == 200){
        // 解析成功
        // 判断数据是否为0
        if (info.length == 0) return this.$message.info('无新增发票')
        info.forEach((e) => {
          this.tableData.push({
            fphm: e.Fphm,
            kprq: e.Kprq,
            gfmc: e.Gfmc,
            hjje: e.Hjje,
            hjse: e.Hjse,
            //保留两位小数
            jshj: (parseFloat(e.Hjje) + parseFloat(e.Hjse)).toFixed(2),
            spxx: e.Spxx,
            is_issued: false,
            khmc: '',
            remark: ''
          })
        })
        this.$message.success("解析成功，请核对发票信息")
        this.displayUpload = false
        this.displayTable = true
      }else {
        //解析失败
        return this.$message.error(s.meta.msg)
      }
    },
    getTableHeight(){
      // const height = window.screen.height
      const height = document.body.clientHeight
      const h = (height-260-40)
      this.tableHeight = h
    },
    getHeaders(){
      const token = window.sessionStorage.getItem('token')
      return {token: token}
    }

  }
}
</script>

<style scoped>
  .uplaod-box{
    width: 360px;
    height: 250px;
    margin: 50px auto;
  }
  .button{
    /*float: right;*/
    /*margin-top: 20px;*/
  }
  .table{
    /*width: 1000px;*/
    /*height: 100%;*/
    overflow-x: scroll !important;
  }
  .info{
    /*float: left;*/
    /*margin-top: 20px;*/
    font-size: 12px;
  }
  .bottom{
    display: flex;
    height: 50px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
</style>