import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "../components/Login"
import Home from "../components/Home"
import Cloud from "../components/Cloud"
import Tax from "../components/Tax"
import Welcome from "../components/Welcome"
import TaxSelect from "@/components/TaxSelect"
import Order from "../components/Order"
import QueryOrder from  '../components/QueryOrder'
import DownLoadFP from "../components/EmailTax/DownLoadFP"
import Settlement from "../components/QueryOrder/Settlement"
import Warehousing from "../components/Inventory/Warehousing"
import OutWarehousing from "../components/Inventory/OutWarehousing"
import SelectWarehousing from "../components/Inventory/SelectWarehousing"
import Goods from "../components/Inventory/Goods";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/welcome'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: "知行邦-登录"
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      title: "知行邦"
    },
    children:[
      {
        path: '/cloud', component: Cloud,
        meta: {title: "知行邦-文件存储"},
      },
      {
        path: '/tax',
        component: Tax,
        meta: {title: "知行邦-发票管理"}
      },
      {
        path: '/select',
        component: TaxSelect,
        meta: {title: "知行邦-发票查询"}
      },
      {
        path: '/download_tax',
        component: DownLoadFP,
        meta: {title: "知行邦-发票下载"}
      },
      {
        path: '/welcome',
        component: Welcome,
        meta: {title: "知行邦-首页"}
      },
      {
        path: '/addorder',
        component: Order,
        meta: {title: "知行邦-添加订单"}
      },
      {
        path: '/orders',
        component: QueryOrder,
        meta: {title: "知行邦-订单查询"}
      },
      {
        path: '/settlement',
        component: Settlement,
        meta: {title: "知行邦-结算记录"}
      },
      {
        path: '/goods',
        component: Goods,
        meta: {title: "知行邦-商品管理"}
      },
      {
        path: '/warehousing',
        component: Warehousing,
        meta: {title: "知行邦-商品入库"}
      },
      {
        path: '/out_warehousing',
        component: OutWarehousing,
        meta: {title: "知行邦-商品出库"}
      },
      {
        path: '/select_warehousing',
        component: SelectWarehousing,
        meta: {title: "知行邦-库存查询"}
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // 访问登录页，放行
  if (to.path == '/login') return next()
  // 获取token
  const tokenStr = window.sessionStorage.getItem('token')
  // 没有token, 强制跳转到登录页
  if (!tokenStr) return next('/login')
  next()
})
export default router
