<!--订单管理->订单查询->编辑-->
<template>
  <div>
    <!--    新加的 没有用-->
    <el-form ref="orderForm" :model="form" label-width="80px" :rules="rules">
      <el-row>
        <el-form-item label="类别：" prop="category">
          <el-col :span="6">
            <el-select v-model="form.category" placeholder="请选择" style="width: 100%">
              <el-option label="服务" :value="1"></el-option>
              <el-option label="商品" :value="2"></el-option>
            </el-select>
          </el-col>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="部门：" prop="department">
          <el-col :span="6">
            <el-select v-model="form.department" placeholder="请选择部门" style="width: 100%">
              <el-option
                  v-for="item in departments"
                  :key="item.department"
                  :label="item.department" :value="item.id"></el-option>
            </el-select>
          </el-col>
          <el-col :span="18">
            <span class="tips">* 如选项内没有要选择的部门请在数据库中手动添加</span>
          </el-col>
        </el-form-item>
      </el-row>

      <el-row :gutter="10">
        <el-form-item label="事项：" prop="item">
          <el-col :span="6">
            <el-input :disabled="disableItem" placeholder="请输入事项  如：打印机维修" v-model="form.item"
                      style="width: 100%"></el-input>
          </el-col>
          <el-col :span="1.5">
            <el-checkbox v-model="hasConsumables" @change="checkBoxChange" label="含配件/耗材"></el-checkbox>
          </el-col>

          <el-col :span="16.5" style="margin-left: 5px;" v-if="hasConsumables">
            <el-tag
                v-for="tag in form.consumables"
                :key="tag"
                closable
                @close="handleTagClose(tag)"
                :type="tag"
                size="small"
                style="margin: 0 3px">
              {{ tag }}
            </el-tag>
          </el-col>
        </el-form-item>
      </el-row>
      <el-form-item label="配件：" v-if="hasConsumables">
        <el-col :span="4">
          <el-autocomplete
              size="mini"
              style="width: 100%"
              class="inline-input"
              v-model="tempConsumables"
              :fetch-suggestions="querySearch"
              placeholder="请输入内容"
          ></el-autocomplete>
        </el-col>
        <el-col :span="1.5" style="margin:0 10px;">
          <el-input-number size="mini" style="width: 110px" v-model="consumablesNum" :min="1" :max="99"
                           label="数量"></el-input-number>
        </el-col>
        <el-col :span="1">
          <el-button type="primary" @click="addTag" size="mini" :disabled="Boolean(!tempConsumables)">添加</el-button>
        </el-col>
      </el-form-item>
      <el-form-item label="经办人：">
        <!--        <el-col :span="6">-->
        <!--          <el-input  placeholder="请输入经办人" v-model="form.manager"></el-input>-->
        <!--        </el-col>-->
        <el-autocomplete
            class="inline-input"
            v-model="form.manager"
            :fetch-suggestions="querySearch1"
            placeholder="请输入经办人"
            :trigger-on-focus="false"
        ></el-autocomplete>
      </el-form-item>

      <el-form-item label="时间：" prop="date">
        <el-col :span="6">
          <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期" v-model="form.date"
                          style="width: 100%;"></el-date-picker>
        </el-col>
      </el-form-item>

      <el-form-item label="费用：" prop="cost">
        <el-col :span="6">
          <el-input placeholder="请输入费用" v-model="form.cost"></el-input>
        </el-col>
        <el-col :span="18">
          <span class="tips">* 如不填写默认将为 0</span>
        </el-col>
      </el-form-item>

      <el-form-item label="服务员：" prop="servers">
        <el-col :span="6">
          <el-select
              style="width: 100%"
              v-model="form.servers"
              multiple
              filterable
              allow-create
              default-first-option
              placeholder="请选择服务员">
            <el-option v-for="i in serverList" :label="i" :value="i"></el-option>
          </el-select>
        </el-col>
        <el-col :span="18">
          <span class="tips">* 可勾选或手动添加服务员</span>
        </el-col>
      </el-form-item>

      <el-form-item label="备注：">
        <el-col :span="8">
          <el-input type="textarea" v-model="form.remark" placeholder="备注"></el-input>
        </el-col>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "EditForm",
  props: {
    id: {
      require: true,
      type: Number
    },
    category: {
      type: Number,
      default: 1,
    },
    department: Number,
    item: String,
    manager: String,
    haoCai: Array,
    date: String,
    cost: Number,
    remark: String,
    servers: Array,
    isConsumables: Boolean
  },
  async created() {
    // 获取部门信息
    const {data: res} = await this.axios.get('departments')
    if (res.meta.code != 200) {
      return this.$message.error(res.meta.msg)
    }
    this.departments = res.data
  },
  async mounted() {
    // 获取耗材信息
    const {data: res} = await this.axios.get('consumables')
    if (res.meta.code != 200) {
      return this.$message.error(res.meta.msg)
    }
    this.consumables = res.data
    // 获取经办人列表
    const {data: res1} = await this.axios.get('pass_by')
    if (res1.meta.code != 200) {
      return this.$message.error('获取经办人列表失败')
    }
    this.managers = res1.data
    this.getServers()
  },
  data() {
    var checkCost = (rule, value, callback) => {
      if (!value) {
        // this.form.cost = 0
        callback()
      } else {
        if (parseFloat(value)) {
          this.form.cost = parseFloat(value)
          callback()
        } else {
          callback(new Error("请输入正整数或小数金额"))
        }
      }
    }
    return {
      form: {
        id: this.id,
        category: this.category,
        department: this.department,
        item: this.item,
        manager: this.manager,
        consumables: this.haoCai,
        date: this.date,
        cost: this.cost,
        remark: this.remark,
        servers: this.servers
      },
      managers: [], //经办人组
      disableItem: false,
      departments: [{id: 1, department: '建规学院'}, {id: 2, department: '交大检测'}],
      hasConsumables: this.isConsumables,
      // 临时存储配件的值
      tempConsumables: '',
      //此consumables是从后端获取的数据
      consumables: '',
      consumablesNum: 1,
      displayPj: this.isConsumables,
      serverList: [],
      rules: {
        department: [
          {required: true, message: '请选择部门', trigger: 'change'}
        ],
        category: [
          {required: true, message: '请选择类别', trigger: 'change'}
        ],
        item: [
          {required: true, message: '请输入事项', trigger: 'blur'}
        ],
        date: [
          {required: true, message: '请选择日期', trigger: 'change'}
        ],
        servers: [
          {required: true, message: '请选择服务员', trigger: 'blur'}
        ],
        cost: [
          {validator: checkCost, trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    async onSubmit() {
      this.$refs.orderForm.validate(async (v) => {
        if (!v) {
          return
        }
        const {data: res} = await this.axios.post('add_order', this.form)
        if (res.meta.code == 200) {
          this.$message.success("添加成功")
          setTimeout(() => {
            location.reload()
          }, 500)
        } else {
          this.$message.error(res.meta.msg)
        }
      })
    },
    addTag() {
      this.form.consumables.push(this.tempConsumables + "×" + this.consumablesNum)
      this.tempConsumables = ''
    },
    // 商品的查询
    querySearch(queryString, cb) {
      const consumables = this.consumables;
      const results = queryString ? consumables.filter(this.createFilter) : consumables;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    // 经办人的查询
    querySearch1(queryString, cb) {
      const managers = this.managers;
      const results = queryString ? managers.filter(this.createFilter1(queryString)) : managers;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(val) {
      const res = val.value.toLowerCase().indexOf(this.tempConsumables.toLowerCase()) > -1
      return res
    },
    createFilter1(queryString) {
      return (restaurant) => {
        return (restaurant.value.indexOf(queryString) > -1);
      }
    },
    handleTagClose(tag) {
      this.form.consumables.splice(this.form.consumables.indexOf(tag), 1)
    },
    checkBoxChange(args) {
      if (args) {
        this.displayPj = true
      } else
        this.displayPj = false
      this.form.consumables = []
    },
    getServers() {
      this.axios.get('/servers').then(res => {
        this.serverList = res.data.data
      })
    }
  },
  watch: {
    form: {
      handler(newVal, oldVal) {
        if (newVal.category == 2) {
          this.form.item = '商品'
          this.disableItem = true
          this.hasConsumables = true
        } else {
          this.disableItem = false
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped>
.tips {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  margin-left: 10px;
  font-size: 12px;
  color: #909399;
}
</style>