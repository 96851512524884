<template>
  <el-card class="box-card">
    <el-row>
      <el-col :span="12">
        <!--      面包屑导航-->
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>发票管理</el-breadcrumb-item>
          <el-breadcrumb-item>发票下载</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <!--        月份选择框-->
      <el-col :span="8">
        <el-select v-model="type" placeholder="发票类型" size="mini" style="width: 100px; margin-right: 10px">
          <el-option label="进项" :value="1"></el-option>
          <el-option label="销项" :value="2"></el-option>
        </el-select>
        <el-date-picker
            v-model="month"
            type="month"
            placeholder="选择查询的月份"
            size="mini"
            value-format="yyyy-MM">
        </el-date-picker>
        <el-button type="primary" size="mini" class="search" @click="select">查询</el-button>
      </el-col>
      <el-col :span="4">
        <el-button size="mini" type="danger" @click="refreshTax" :loading="refreshLoading">{{ buttonText }}</el-button>
        <el-button size="mini" type="success">上传发票</el-button>
      </el-col>
    </el-row>
<!--    表格-->
    <div class="table">
      <!--                表格-->
      <el-table :data="tableData" border size="mini" style="width: 100%" :fit="true"
                :highlight-current-row="true"
                :stripe = 'true'
                :height="tableHeight"
                v-loading="loading"
                element-loading-text="数据加载中"
                element-loading-spinner="el-icon-loading">
        <el-table-column type="index" label="#"></el-table-column>
        <el-table-column label="发票名称" width="300" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.fp_name">{{scope.row.fp_name}}</span>
            <el-link v-if="!scope.row.fp_name" type="danger">疑似发票</el-link>
          </template>
        </el-table-column>
        <el-table-column label="发票类型" width="120" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.type !== 2" type="success">进项</el-tag>
            <el-tag v-if="scope.row.type === 2">销项</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="发票来源" width="120" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.type === 1" >京东</span>
            <span v-if="scope.row.type === 2" >知行邦</span>
            <span v-if="scope.row.type === 3" >其他</span>
          </template>
        </el-table-column>
        <el-table-column prop="g_time" label="获取时间" width="150" align="center"></el-table-column>
        <el-table-column prop="belong" label="归属时间" width="150" align="center"></el-table-column>
        <el-table-column prop="remark" label="备注" width="300" align="center"></el-table-column>
        <!--                    按钮-->
        <el-table-column min-width="200" align="center" label="操作" class="option" >
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="下载"  placement="top-start" :enterable="false">
              <el-button type="primary" size="mini" ><i class="el-icon-download"></i></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top-start" :enterable="false">
              <el-popconfirm class="confirmButton" confirm-button-text='删除' cancel-button-text='取消' icon="el-icon-info" icon-color="rgb(255,153,0)" title="确定删除吗？">
                <el-button type="danger" size="mini" slot="reference"><i class="el-icon-delete"></i></el-button>
              </el-popconfirm>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="编辑" placement="top-start" :enterable="false">
              <el-button type="warning" size="mini" @click="editTax(scope.row)"><i class="el-icon-edit"></i></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--    编辑发票-->
    <EditTax v-if="showEditForm" :tax-data="tempEditData" @closeIt="closeEditForm" @refresh="getFp"></EditTax>
  </el-card>
</template>

<script>
import axios from "axios";
import EditTax from "./EditTax";

export default {
  name: "DownLoadFP",
  components: {EditTax},
  data(){
    return{
      tableData: [],
      month:'',
      loading: false,
      tempEditData:{}, //临时存放要修改的数据
      showEditForm: false,
      buttonText: '获取最新发票',
      refreshLoading: false,
      type: '', // 查询时的发票类型
    }
  },
  created() {
    this.getFp()
  },
  methods:{
    select(){
      axios.get(`/select_fp?type=${this.type}&date=${this.month}`).then(res=>{
        if (res.data.meta.code === 200){
          this.tableData = res.data.data
        }
      })
    },
    getFp(){
      this.loading = true
      let that = this
      axios.get('get_fp').then(res=>{
        that.tableData = res.data.data
        this.loading = false
      }).catch(()=>{
        this.$message.error('获取发票失败')
        this.loading = false
      })
    },
    editTax(arg){
      this.showEditForm = true
      this.tempEditData = arg
    },
    closeEditForm(e){
      if (e === 'close') this.showEditForm = false
    },
    refreshTax(){
      this.refreshLoading = true
      this.buttonText = '正在获取最新发票'
      axios.get('/refresh_fp').then(res=>{
        this.refreshLoading = false
        this.buttonText = '刷新完成'
        this.tableData = res.data.data
        setTimeout(()=>{
          this.buttonText = '获取最新发票'
        }, 3000)
      }).catch(err=>{
        this.refreshLoading = false
        this.buttonText = '刷新失败'
        setTimeout(()=>{
          this.buttonText = '获取最新发票'
        }, 3000)
      })
    }
  },
  computed:{
    tableHeight(){
      return  document.body.clientHeight - 200
    }
  }
}
</script>

<style scoped>
  .search{
    margin-left: 10px;
  }
  .item{
    margin: 0 5px;
  }
</style>