<template>
    <div class="background">
<!--        卡片区域-->
        <el-card class="box-card" v-loading="loading" element-loading-text="登录中"
                 element-loading-spinner="el-icon-loading">
<!--            表单区域-->
            <div class="form-area">
                <div class="head-img">
                    <img src="../assets/logo.png" alt="">
                </div>
                <el-form class="login_form" :model="ruleForm" :rules="rules" ref="loginForm">
                    <el-form-item prop="user">
                        <el-input placeholder="请输入账号" v-model="ruleForm.user" clearable prefix-icon="el-icon-user"></el-input>
                    </el-form-item>
                    <el-form-item prop="password" >
                        <el-input placeholder="请输入密码" v-model="ruleForm.password" type="password" @keyup.native.enter="login" clearable show-password prefix-icon="el-icon-lock"></el-input>
                    </el-form-item>
                    <div class="botton">
                        <el-button type="success" @click="login">登录</el-button>
                        <el-button type="primary" @click="reset">重置</el-button>
                    </div>
                </el-form>
<!--                按钮-->
                <div class="bottom-url">
                    <a href="#" class="span-left" @click="forget">忘记密码</a>
                    <a href="#" class="span-right" @click="pubPan">共享网盘</a>
                </div>
            </div>
        </el-card>
        <div class="copy-right">
            <p>CopyRight ©2021 重庆知行邦科技有限公司 版权所有</p>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "Login",
    data(){
        return {
            ruleForm:{
                user:'',
                password:''
            },
            rules:{
                user:[{ required: true, message: '请输入账号', trigger: 'blur' },],
                password:[{ required: true, message: '请输入密码', trigger: 'blur' }]
            },
            loading: false
        }
    },
    methods:{
        login(){
            //校验表单
            this.$refs.loginForm.validate((valid) => {
                if (! valid) return
                this.loading = true
                // 发起请求
                axios.post('login', this.ruleForm).then((res)=>{
                    this.loading = false
                    if (res.data.meta.code != 200) return this.$message.error(res.data.meta.msg)
                    // 将token储存到session中
                    window.sessionStorage.setItem('token', res.data.data.token)
                    window.sessionStorage.setItem('loginTime', res.data.data.login_time)
                    window.sessionStorage.setItem('nikeName', res.data.data.nikename)
                    this.$message.success("登录成功")
                    // 跳转
                    this.$router.push('/welcome')
                }).catch(()=>{
                  this.loading = false
                  return this.$message.error("网络错误")
                })
            })
            return

        },
        reset(){
            this.ruleForm.user = ''
            this.ruleForm.password = ''
        },
        forget(){
            this.$message('请联系管理员')
        },
        pubPan(){
            this.$message('开发中')
        }
    }
}

</script>

<style scoped>
    .background{
        width: 100vw;
        height: 100vh;
        /*background-color: red;*/
        background-image: url(https://oa.tensite.cn/down/img/bg.jpg);
        background-size: cover;
        background-position: center;
    }
    .box-card{
        width: 400px;
        height: 300px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-200px, -150px);
        overflow: initial;
    }
    .form-area{
        margin-top: 50px;
        z-index: 10;
    }
    .head-img{
        width: 100px;
        height: 100px;
        position: absolute;
        top: -60px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 50%;
        background-color: #fff;
        /*box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);*/
        padding: 5px;
        z-index: 99999999;
    }
    .head-img img{
        /*width: 100px;*/
        height: 100%;
        border-radius: 50%;
    }
    .botton{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
    .bottom-url{
        position: absolute;
        bottom: 5px;
        font-size: 12px;
        width: 90%;
    }
    .bottom-url .span-left{
        float: left;
    }
    .bottom-url .span-right{
        float: right;
    }
    .copy-right{
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
        color: aliceblue;
        font-size: 12px;
    }
</style>