<!--订单管理->添加订单-->
<template>
  <div>
    <el-card class="box-card">
      <!--      面包屑导航-->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>订单管理</el-breadcrumb-item>
        <el-breadcrumb-item>添加订单</el-breadcrumb-item>
      </el-breadcrumb>
      <el-divider></el-divider>
      <div class="form_area">
        <!--      表单区域-->
        <el-form ref="orderForm" :model="form" label-width="80px" :rules="rules">
          <el-form-item label="类别：" prop="category">
            <el-col :span="4">
              <el-select v-model="form.category" placeholder="请选择" style="width: 100%">
                <el-option label="服务" :value="1"></el-option>
                <el-option label="商品" :value="2"></el-option>
              </el-select>
            </el-col>
          </el-form-item>

          <el-form-item label="部门：" prop="department">
            <el-col :span="4">
              <el-select v-model="form.department" placeholder="请选择部门" style="width: 100%">
                <el-option
                    v-for="item in departments"
                    :key="item.department"
                    :label="item.department" :value="item.id"></el-option>
              </el-select>
            </el-col>
            <el-col :span="20">
              <span class="tips">* 如选项内没有要选择的部门请在数据库中手动添加</span>
            </el-col>
          </el-form-item>

          <el-form-item label="事项：" prop="item">
            <el-col :span="4">
              <el-input :disabled="disableFormItem" placeholder="请输入事项  如：打印机维修" v-model="form.item"
                        style="width: 100%"></el-input>
            </el-col>
            <el-col :span="0.5">
              <div style="width: 10px;height: 10px"></div>
            </el-col>
            <el-col :span="1.5">
              <el-checkbox v-model="hasConsumables" @change="checkBoxChange" label="含配件/耗材"></el-checkbox>
            </el-col>

            <el-col :span="17" style="margin-left: 5px;" v-if="hasConsumables">
              <el-tag
                  v-for="tag in form.consumables"
                  :key="tag"
                  closable
                  @close="handleTagClose(tag)"
                  :type="tag"
                  size="small"
                  style="margin: 0 3px">
                {{ tag }}
              </el-tag>
            </el-col>
          </el-form-item>
          <el-form-item label="配件：" v-if="displayPj">
            <el-col :span="4">
              <el-autocomplete
                  size="mini"
                  style="width: 100%"
                  class="inline-input"
                  v-model="tempConsumables"
                  :fetch-suggestions="querySearch"
                  placeholder="请输入内容"
              ></el-autocomplete>
            </el-col>
            <el-col :span="1.5" style="margin:0 10px;">
              <el-input-number size="mini" style="width: 110px" v-model="consumablesNum" :min="1" :max="99"
                               label="数量"></el-input-number>
            </el-col>
            <el-col :span="1">
              <el-button type="primary" @click="addTag" size="mini" :disabled="Boolean(!tempConsumables)">添加</el-button>
            </el-col>
          </el-form-item>
          <el-form-item label="经办人：">
            <el-col :span="4">
              <el-autocomplete
                  class="inline-input"
                  v-model="form.manager"
                  :fetch-suggestions="querySearch1"
                  placeholder="请输入经办人"
                  :trigger-on-focus="false"
                  style="width: 100%"
              ></el-autocomplete>
            </el-col>
          </el-form-item>

          <el-form-item label="时间：" prop="date">
            <el-col :span="4">
              <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期" v-model="form.date"
                              style="width: 100%;"></el-date-picker>
            </el-col>
          </el-form-item>

          <el-form-item label="费用：" prop="cost">
            <el-col :span="4">
              <el-input placeholder="请输入费用" v-model="form.cost"></el-input>
            </el-col>
            <el-col :span="6">
              <span class="tips">* 如不填写默认将为 0</span>
            </el-col>
          </el-form-item>

          <el-form-item label="服务员：" prop="servers">
            <el-col :span="4">
              <el-select
                  style="width: 100%"
                  v-model="form.servers"
                  multiple
                  filterable
                  allow-create
                  default-first-option
                  placeholder="请选择服务员">
                <el-option v-for="i in serverList" :label="i" :value="i"></el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <span class="tips">* 可勾选或手动添加服务员</span>
            </el-col>
          </el-form-item>

          <el-form-item label="备注：">
            <el-col :span="4">
              <el-input type="textarea" v-model="form.remark" placeholder="备注"></el-input>
            </el-col>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="onSubmit" size="mini">提交</el-button>
          </el-form-item>
        </el-form>
        <!--      成功提示-->
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "Order",
  async created() {
    // 获取部门信息
    const {data: res} = await this.axios.get('departments')
    if (res.meta.code != 200) {
      return this.$message.error(res.meta.msg)
    }
    this.departments = res.data
  },
  async mounted() {
    // 获取耗材信息
    const {data: res} = await this.axios.get('consumables')
    if (res.meta.code != 200) {
      return this.$message.error(res.meta.msg)
    }
    this.consumables = res.data
    // 获取经办人列表
    const {data: res1} = await this.axios.get('pass_by')
    if (res1.meta.code != 200) {
      return this.$message.error('获取经办人列表失败')
    }
    this.managers = res1.data
    this.getServers()
  },
  data() {
    var checkCost = (rule, value, callback) => {
      if (!value) {
        // this.form.cost = 0
        callback()
      } else {
        if (parseFloat(value)) {
          this.form.cost = parseFloat(value)
          callback()
        } else {
          callback(new Error("请输入正整数或小数金额"))
        }
      }

    }
    return {
      form: {
        category: 1,
        department: '',
        item: '',
        manager: '',
        consumables: [],
        date: '',
        cost: '',
        remark: '',
        servers: ''
      },
      managers: [], //经办人组
      departments: [{id: 1, department: '建规学院'}, {id: 2, department: '交大检测'}],
      hasConsumables: false,
      disableFormItem: false,
      // 临时存储配件的值
      tempConsumables: '',
      //此consumables是从后端获取的数据
      consumables: '',
      consumablesNum: 1,
      displayPj: false,
      serverList: [],
      rules: {
        department: [
          {required: true, message: '请选择部门', trigger: 'change'}
        ],
        category: [
          {required: true, message: '请选择类别', trigger: 'change'}
        ],
        item: [
          {required: true, message: '请输入事项', trigger: 'blur'}
        ],
        date: [
          {required: true, message: '请选择日期', trigger: 'change'}
        ],
        servers: [
          {required: true, message: '请选择服务员', trigger: 'blur'}
        ],
        cost: [
          {validator: checkCost, trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    async onSubmit() {
      this.$refs.orderForm.validate(async (v) => {
        if (!v) {
          return
        }
        const {data: res} = await this.axios.post('add_order', this.form)
        if (res.meta.code == 200) {
          this.$message.success("添加成功")
          setTimeout(() => {
            location.reload()
          }, 1000)
        } else {
          this.$message.error(res.meta.msg)
        }
      })
    },
    addTag() {
      this.form.consumables.push(this.tempConsumables + "×" + this.consumablesNum)
      this.tempConsumables = ''
    },
    querySearch(queryString, cb) {
      const consumables = this.consumables;
      const results = queryString ? consumables.filter(this.createFilter) : consumables;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    // 经办人的查询
    querySearch1(queryString, cb) {
      const managers = this.managers;
      const results = queryString ? managers.filter(this.createFilter1(queryString)) : managers;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(val) {
      const res = val.value.toLowerCase().indexOf(this.tempConsumables.toLowerCase()) > -1
      return res
    },
    createFilter1(queryString) {
      return (restaurant) => {
        return (restaurant.value.indexOf(queryString) > -1);
      }
    },
    handleTagClose(tag) {
      this.form.consumables.splice(this.form.consumables.indexOf(tag), 1)
    },
    checkBoxChange(args) {
      if (args) {
        this.displayPj = true
      } else
        this.displayPj = false
      this.form.consumables = []
    },
    getServers() {
      this.axios.get('/servers').then(res => {
        this.serverList = res.data.data
      })
    }
  },
  watch: {
    form: {
      handler(newV) {
        if (newV.category === 2) {
          this.displayPj = this.hasConsumables = true
          this.disableFormItem = true
          this.form.item = '商品'
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.tips {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  margin-left: 10px;
  font-size: 12px;
  color: #909399;
}

.form_area {
  height: calc(100vh - 200px);
  overflow-y: scroll;
}
/deep/ .form_area::-webkit-scrollbar {
  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 12px;
}

/deep/ .form_area::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  cursor: pointer;
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(64, 158, 255, 0.84);
}

/deep/ .form_area::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #ededed;
}
</style>