<template>
  <div>
    <el-card class="first">
      <span class="indexTitle">概况</span>
      <div class="data_info">
        <div>
          <span class="title">今日订单</span>
          <span class="data"><a href="" @click.prevent="$router.push('/orders')">{{ indexInfo.todayOrder }} 笔</a></span>
        </div>
        <div>
          <span class="title">今日营业额</span>
          <span class="data"><a href="" @click.prevent="$router.push('/orders')">{{ indexInfo.todayPrice }} 元</a></span>
        </div>
        <div>
          <span class="title">总订单</span>
          <span class="data"><a href="" @click.prevent="$router.push('/orders')">{{ indexInfo.totalOrder }} 笔</a></span>
        </div>
        <div>
          <span class="title">总营业额</span>
          <span class="data"><a href="" @click.prevent="$router.push('/orders')">{{ indexInfo.totalPrice }} 元</a></span>
        </div>
      </div>
    </el-card>
    <el-card style="margin-top:10px" class="charts">
      <span class="indexTitle">订单数据</span>
      <div class="echarts">
        <div id="order" :style="{width: '50%', height: '300px'}"></div>
        <div id="sales" :style="{width: '50%', height: '300px'}"></div>
        <div id="server" :style="{width: '50%', height: '300px'}"></div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "Welcome",
  async created() {
    // 获取表格高度
    const height = document.body.clientHeight
    const h = (height - 250)
    this.Height = h
    //获取信息
    let indexInfoData = await this.getInfo()
    this.salesOption = {
      title: {text: '营业额数据', left: '45%', transform: 'translateX(-50%)'},
      xAxis: {
        data: ['12', '21']
      },
      tooltip: {show: true},
      yAxis: {},
      series: [
        {
          type: 'bar',
          data: [12, 123]
        }
      ]
    };
    this.orderOption = {
      title: {text: '订单数据', left: '45%'},
      xAxis: {
        data: ['12', '21']
      },
      tooltip: {show: true},
      yAxis: {},
      series: [
        {
          type: 'bar',
          data: [12, 123]
        }
      ]
    };
    this.severOption = {
      // title: {text: '服务员订单占比', left: '45%'},
      series: [
        {
          type: 'pie',
          data: [],
        }
      ],
      tooltip: {show: true},
    };
    this.orderOption.xAxis.data = indexInfoData.months
    this.orderOption.series[0].data = indexInfoData.ordersData
    this.salesOption.xAxis.data = indexInfoData.months
    this.salesOption.series[0].data = indexInfoData.salesData
    this.severOption.series[0].data = indexInfoData.servers
  },
  mounted() {
    setTimeout(() => {
      this.drawLine();
    }, 500)
  },
  data() {
    return {
      Height: 750,
      indexInfo: {},
      msg: 'Welcome to Your Vue.js App',
      orderOption: {},
      salesOption: {},
      severOption: {}
    }
  },
  methods: {
    async getInfo() {
      const {data: res} = await this.axios.get('/index_info')
      if (res.meta.code === 200) {
        this.indexInfo = res.data
        return res.data
      }

    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let order = this.$echarts.init(document.getElementById('order'))
      let sales = this.$echarts.init(document.getElementById('sales'))
      let server = this.$echarts.init(document.getElementById('server'))
      // 绘制图表
      order.setOption(this.orderOption);
      sales.setOption(this.salesOption);
      server.setOption(this.severOption);
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-card__body {
  padding: 5px 0;
}

.first {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
}

.indexTitle {
  display: flex;
  font-size: 16px;
  line-height: 40px;
  color: #666666;
  width: 100%;
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
  padding: 0 0 5px 15px;
}

.data_info {
  display: flex;
  justify-content: space-between;
  padding: 10px 50px;

  div {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin-right: 10px;
    height: 100px;
    background-color: rgb(64, 158, 255);
    border-radius: 5px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
    }

    span {
      margin-bottom: 10px;
    }

    .title {
      font-size: 17px;
      color: #fff;
      font-weight: bold;
    }

    .data {
      color: #fff;
      a{
        color: #fff;
      }
    }
  }
}

.charts {

}

.echarts {
  display: flex;
}
</style>