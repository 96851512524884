import axios from "axios";
import config from '../config'

const down = axios.create(
    {
        baseURL: config.baseURL,
        headers:{
            token: window.sessionStorage.token
        },
        responseType:"blob"
    }
)

export default down