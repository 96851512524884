<template>
  <el-card :style="{'height': get_height}">
    <!--      面包屑导航-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>库存管理</el-breadcrumb-item>
      <el-breadcrumb-item>商品入库</el-breadcrumb-item>
    </el-breadcrumb>
    <div>

    </div>
  </el-card>
</template>

<script>
export default {
  name: "Warehousing",
  data(){
    return{

    }
  },
  computed:{
    get_height(){
      let height = document.body.clientHeight
      return (height - 130) + 'px'
    }
  }
}
</script>

<style scoped lang="scss">

</style>