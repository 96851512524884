<template>
  <el-card class="box-card">
    <div v-if="!license" class="tips">
      <div>
        <i :class="{'el-icon-loading': viliding}"
           style="color: rgb(64,158,255);margin-right: 10px"></i>
        <span style="font-size: 14px">{{ tips }}</span>
      </div>
    </div>
    <div v-if="license">
      <el-row>
        <el-col :span="18">
          <!--      面包屑导航-->
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>发票管理</el-breadcrumb-item>
            <el-breadcrumb-item>发票查询</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
        <!--        月份选择框-->
        <el-col :span="6">
          <el-date-picker
              v-model="month"
              type="month"
              placeholder="选择查询的月份"
              size="mini"
              value-format="yyyyMM">
          </el-date-picker>
          <el-button type="primary" size="mini" class="search" @click="select">查询</el-button>
        </el-col>
      </el-row>
      <div class="table">
        <el-scrollbar>
          <el-table
              v-loading="loading"
              element-loading-text="数据加载中"
              element-loading-spinner="el-icon-loading"
              size="small"
              :data="tableData"
              style="width: 100%"
              :height="tableHeight">
            <el-table-column
                fixed
                type="index"
                label="#"
                width="50px">
            </el-table-column>
            <el-table-column
                prop="fphm"
                label="发票号码"
                width="100px">
            </el-table-column>
            <el-table-column
                prop="kprq"
                label="开票日期"
                width="100px">
            </el-table-column>
            <el-table-column
                prop="gfmc"
                label="购方名称"
                min-width="100px"
                :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column
                prop="spxx"
                label="商品信息"
                width="180px"
                :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column
                prop="jshj"
                label="价税合计"
                width="80px">
            </el-table-column>
            <!--          发票类型，0为正常，1为代开，2为作废-->
            <el-table-column
                prop="bill_type"
                label="发票类型"
                width="80px"
                align="center">
              <template slot-scope="scope">
                <el-tag type="danger" v-if="scope.row.bill_type == 1" size="small">代开</el-tag>
                <el-tag type="success" v-if="scope.row.bill_type == 0" size="small">正常</el-tag>
                <el-tag type="info" v-if="scope.row.bill_type == 2" size="small">作废</el-tag>
              </template>
            </el-table-column>
            <el-table-column
                prop="khmc"
                label="客户名称"
                align="center"
                :show-overflow-tooltip="true"
                min-width="100px">
            </el-table-column>
            <el-table-column
                prop="is_get"
                label="是否到账"
                min-width="80px">
              <template slot-scope="scope">
                <el-switch
                    v-model="scope.row.is_get"
                    @change="changeGet(scope.row)"
                    :disabled="scope.row.bill_type == 2 ? true : false"
                    active-color="#13ce66">
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column
                prop="is_back"
                label="是否返还"
                min-width="80px">
              <template slot-scope="scope">
                <el-switch
                    v-model="scope.row.is_back"
                    :disabled="scope.row.bill_type != 1 ? true : false"
                    active-color="#13ce66"
                    @change="changeBack(scope.row)">
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column
                prop="yingfan"
                label="应返"
                min-width="80px"
                align="center">
            </el-table-column>
            <el-table-column
                prop="remark"
                :show-overflow-tooltip="true"
                label="备注"
                width="100">
            </el-table-column>
            <el-table-column
                prop="otion"
                label="操作"
                width="270"
                align="center">
              <template scope="scope">
                <el-popconfirm title="是否要作废" @confirm="invalid(scope.row)">
                  <el-button type="warning" slot="reference" size="mini">作废</el-button>
                </el-popconfirm>
                <el-button type="primary" size="mini" style="margin: 0 10px" @click="editTaxInfo(scope.row)">编辑
                </el-button>
                <el-popconfirm title="是否要删除" @confirm="delete_item(scope.row)">
                  <el-button type="danger" slot="reference" size="mini">删除</el-button>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </el-scrollbar>
        <!--      修改信息的对话框-->
        <el-dialog
            title="修改发票信息"
            :visible.sync="dialogVisible"
            width="30%">
          <div class="tax_info">
            <p class="edit_info">发票号码：{{ editInfo.fphm }}</p>
            <p class="edit_info">开票日期：{{ editInfo.kprq }}</p>
            <p class="edit_info">购方名称：{{ editInfo.gfmc }}</p>
            <p class="edit_info">商品信息：{{ editInfo.spxx }}</p>
            <p class="edit_info">价税合计：￥{{ editInfo.jshj }}</p>
            <el-form ref="editForm" :model="editInfo" size="big" label-position="left" label-width="82px">
              <el-form-item label="发票类型：">
                <el-select v-model="editInfo.bill_type" placeholder="发票类型">
                  <el-option label="正常" :value="0">正常</el-option>
                  <el-option label="代开" :value="1">代开</el-option>
                  <el-option label="作废" :value="2">作废</el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="客户名称：">
                <el-input v-model="editInfo.khmc" placeholder="客户名称"></el-input>
              </el-form-item>
              <el-form-item prop="tax_rate" label="税率：" :rules="[
            { validator: checkRate, trigger: 'blur', required: true }
          ]">
                <el-input v-model="editInfo.tax_rate" placeholder="税率"></el-input>
              </el-form-item>
              <el-form-item label="备注：">
                <el-input type="textarea" v-model="editInfo.remark"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
          <el-button type="primary" @click="changeTaxInfo()" size="mini">确 定</el-button>
        </span>
        </el-dialog>
      </div>
      <div class="bottom">
        <span class="tax_num"><i class="el-icon-document-remove"></i> 共 {{ this.tableData.length }} 张发票</span>
      </div>
    </div>
  </el-card>
</template>


<script>
import axios from "axios";

export default {
  name: "TaxSelect",
  created() {
    this.getTableHeight()
    this.getMonth()
    this.getAll()

  },
  data() {
    return {
      month: '',
      tableHeight: 300,
      tableData: [],
      dialogVisible: false,
      editInfo: {},
      loading: true,
      license: false,
      tips: '正在校验权限...',
      viliding: true
    }
  },
  methods: {
    checkRate(rule, value, callback) {
      if (!value) {
        return callback(new Error('税率不能为空'));
      }
      if (0 <= parseFloat(value) & parseFloat(value) <= 1) {
        callback()
      } else {
        callback(new Error('输入0-1之间的税率'))
      }
    },
    //点击编辑按钮触发
    editTaxInfo(s) {
      const d = {}
      for (var i in s) {
        d[i] = s[i]
      }
      this.editInfo = d
      this.dialogVisible = true
    },
    // 编辑后点确定时触发
    changeTaxInfo() {
      this.$refs['editForm'].validate(async (valid) => {
        if (valid) {
          const {data: res} = await axios.post('update_tax', this.editInfo)
          if (res.meta.code === 403) {
            this.license = false
            this.viliding = false
            this.tips = '抱歉，您无权限访问此功能'
          }else {
            this.license = true
            this.viliding = false
          }
          console.log(res)
          if (res.meta.code === 200) {
            this.tableData[this.tableData.findIndex(e => e.fphm == this.editInfo.fphm)].remark = this.editInfo.remark
            this.tableData[this.tableData.findIndex(e => e.fphm == this.editInfo.fphm)].bill_type = this.editInfo.bill_type
            this.tableData[this.tableData.findIndex(e => e.fphm == this.editInfo.fphm)].tax_rate = this.editInfo.tax_rate
            this.tableData[this.tableData.findIndex(e => e.fphm == this.editInfo.fphm)].remark = this.editInfo.remark
            this.$message.success("更新成功")
            this.dialogVisible = false
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          return this.$message.error("输入的数据不合法")
        }
      })
    },
    // 获取表格高度
    getTableHeight() {
      // const height = window.screen.height
      const height = document.body.clientHeight
      const h = (height - 250)
      this.tableHeight = h
    },
    // 获取当前年月
    getMonth() {
      axios.get('time').then((res) => {
        this.month = res.data.month
      })
    },
    // 选择月份后查询发票
    async select() {
      this.loading = true
      const {data: res} = await axios.get(this.month == null ? 'select_tax/all' : `select_tax/${this.month}`)
      if (res.meta.code === 403) {
        this.license = false
        this.viliding = false
        this.tips = '抱歉，您无权限访问此功能'
      }else {
        this.license = true
        this.viliding = false
      }
      if (res.meta.code !== 200) return this.$message.error('当前月份无发票数据')
      const tax_data = res.data.tax
      tax_data.forEach((i) => {
        // 计算价税合计
        i.jshj = i.hjje + i.hjse
        // 计算应返金额
        if (i.bill_type === 1) {
          i.yingfan = (i.jshj * (1 - i.tax_rate)).toFixed(2)
        } else {
          i.yingfan = "-"
        }
      })
      this.tableData = tax_data
      this.loading = false
    },
    // 查询所有发票
    async getAll() {
      this.loading = true
      const {data: res} = await axios.get('select_tax/all')
      if (res.meta.code === 403) {
        this.license = false
        this.viliding = false
        this.tips = '抱歉，您无权限访问此功能'
      }else {
        this.license = true
        this.viliding = false
      }
      if (res.meta.code !== 200) return this.$message.error(res.meta.msg)
      const tax_data = res.data.tax
      tax_data.forEach((i) => {
        // 计算价税合计
        i.jshj = i.hjje + i.hjse
        // 计算应返金额
        if (i.bill_type === 1) {
          i.yingfan = (i.jshj * (1 - i.tax_rate)).toFixed(2)
        } else {
          i.yingfan = "-"
        }
      })
      this.tableData = tax_data
      this.loading = false
    },
    // 是否到账开关切换
    async changeGet(scope) {
      const status = scope.is_get ? 1 : 0
      const {data: res} = await axios.get(`is_get?fphm=${scope.fphm}&status=${status}`)
      if (res.meta.code === 403) {
        this.license = false
        this.viliding = false
        this.tips = '抱歉，您无权限访问此功能'
      }else {
        this.license = true
        this.viliding = false
      }
    },
    // 是否返还开关切换
    async changeBack(scope) {
      const status = scope.is_back ? 1 : 0
      const {data: res} = await axios.get(`is_back?fphm=${scope.fphm}&status=${status}`)
      if (res.meta.code === 403) {
        this.license = false
        this.viliding = false
        this.tips = '抱歉，您无权限访问此功能'
      }else {
        this.license = true
        this.viliding = false
      }
      if (res.meta.code !== 200) {
        this.$message.error(res.meta.msg)
        scope.is_back = !scope.is_back
        return
      }
    },
    // 作废发票
    async invalid(scope) {
      if (scope.bill_type === 2) {
        return this.$message.warning('当前发票已经作废，无需重复作废')
      } else {
        const {data: res} = await axios.get(`/void?fphm=${scope.fphm}`)
        if (res.meta.code === 200) {
          scope.bill_type = 2
          return this.$message.success('作废成功')
        } else {
          return this.$message.error(res.meta.msg)
        }
        if (res.meta.code === 403) {
          this.license = false
          this.viliding = false
          this.tips = '抱歉，您无权限访问此功能'
        }else {
          this.license = true
          this.viliding = false
        }
      }
    },
    // 删除发票
    async delete_item(scope) {
      const {data: res} = await axios.get(`/delete_tax?fphm=${scope.fphm}`)
      if (res.meta.code === 200) {
        this.tableData.splice(this.tableData.findIndex(e => e.fphm === scope.fphm), 1)
        return this.$message.success('删除成功')
      } else {
        return this.$message.error(res.meta.msg)
      }
      if (res.meta.code === 403) {
        this.license = false
        this.viliding = false
        this.tips = '抱歉，您无权限访问此功能'
      }else {
        this.license = true
        this.viliding = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.tips {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search {
  margin-left: 10px;
}

.edit_info {
  line-height: 32px;
}

.tax_info {
  width: 90%;
  margin: 0 auto;
}

.input:nth-child(1) {
  float: left;
}

.bottom {
  height: 50px;
  /*background-color: red;*/
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 14px;
  color: #909399;
}

.tax_num {

}
</style>